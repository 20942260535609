<template>
  <div>
    <Loading :enable="loading"/>
    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-user"></i> Informações do cliente
        </h5>
      </div>
      <div class="w-100 p-3" v-if="client.name">
        <b-row>
            <b-col lg="6">
              <span>Cliente</span> <br>
              <h4>{{ client.name }}</h4>
            </b-col>
            <b-col lg="3">
              <span>Cidade</span> <br>
              <h4>{{ client.city.name }}</h4>
            </b-col>
            <b-col lg="3">
              <span>Estado</span> <br>
              <h4>{{ client.city.province.name }}</h4>
            </b-col>
        </b-row>
      </div>
    </b-row>

    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-pen"></i> Adicione as fotos <span class="text-danger">(Obrigatório)</span>
        </h5>
      </div>

      <div class="w-100 p-3">
        <b-row class="pb-5">
          <b-col lg="4">
            <h5 class="text-center">Foto Medidor</h5>
            <div class="w-100 py-3 container-img">
              <div class="h-100 position-relative">
                <img :src="foto_medidor" class="h-100" alt="">
                <div class="hover">
                  <div v-if="this.payload.foto_medidor.status === 'rejected'">
                    <i class="fas fa-ban"></i><br>
                    <strong>IMAGEM REJEITADA</strong>
                  </div>
                </div>
              </div>
              <div class="w-100 py-3">
                <b-button
                v-if="$acl.check('isAdmin')"
                variant="warning"
                size="sm"
                @click="confirmReject(payload.foto_medidor.id)"
                class="mr-2">
                  Reprovar
                </b-button>
                <b-button variant="danger" size="sm" @click="confirmRemove(payload.foto_medidor.id)">
                  Remover
                </b-button>
              </div>
            </div>
            <b-form-file
            v-if="!payload.foto_medidor.id"
            name="foto_medidor"
            @change="setImage"
            browse-text="Selecionar"
            accept="image/*"
            placeholder="Selecione uma imagem"
            :class="{ 'form-group--error': $v.payload.foto_medidor.file_url.$error }"
            v-model="$v.payload.foto_medidor.file_url.$model"
            :state="$v.payload.foto_medidor.file_url.$dirty ?
            !$v.payload.foto_medidor.file_url.$error : null"
            ></b-form-file>
          </b-col>

          <b-col lg="4">
            <h5 class="text-center">Foto Disjuntor</h5>
            <div class="w-100 py-3 container-img">
              <div class="h-100 position-relative">
                <img :src="foto_disjuntor" class="h-100" alt="">
                <div class="hover">
                  <div v-if="this.payload.foto_disjuntor.status === 'rejected'">
                    <i class="fas fa-ban"></i><br>
                    <strong>IMAGEM REJEITADA</strong>
                  </div>
                </div>
              </div>
              <div class="w-100 py-3">
                <b-button
                v-if="$acl.check('isAdmin')"
                variant="warning"
                size="sm"
                @click="confirmReject(payload.foto_disjuntor.id)"
                class="mr-2">
                  Reprovar
                </b-button>
                <b-button variant="danger" size="sm" @click="confirmRemove(payload.foto_disjuntor.id)">
                  Remover
                </b-button>
              </div>
            </div>
            <b-form-file
            v-if="!payload.foto_disjuntor.id"
            name="foto_disjuntor"
            @change="setImage"
            accept="image/*"
            placeholder="Selecione uma imagem"
            browse-text="Selecionar"
            :class="{ 'form-group--error': $v.payload.foto_disjuntor.file_url.$error }"
            v-model="$v.payload.foto_disjuntor.file_url.$model"
            :state="$v.payload.foto_disjuntor.file_url.$dirty ?
            !$v.payload.foto_disjuntor.file_url.$error : null"
            ></b-form-file>
          </b-col>
          <b-col lg="4">
            <h5 class="text-center">Foto Padrão</h5>
            <div class="w-100 py-3 container-img">
              <div class="h-100 position-relative">
                <img :src="foto_padrao" class="h-100" alt="">
                <div class="hover">
                  <div v-if="this.payload.foto_padrao.status === 'rejected'">
                    <i class="fas fa-ban"></i><br>
                    <strong>IMAGEM REJEITADA</strong>
                  </div>
                </div>
              </div>
              <div class="w-100 py-3">
                <b-button
                v-if="$acl.check('isAdmin')"
                variant="warning"
                size="sm"
                @click="confirmReject(payload.foto_padrao.id)"
                class="mr-2">
                  Reprovar
                </b-button>
                <b-button variant="danger" size="sm" @click="confirmRemove(payload.foto_padrao.id)">
                  Remover
                </b-button>
              </div>
            </div>
            <b-form-file
            v-if="!payload.foto_padrao.id"
            name="foto_padrao"
            @change="setImage"
            accept="image/*"
            placeholder="Selecione uma imagem"
            browse-text="Selecionar"
            :class="{ 'form-group--error': $v.payload.foto_padrao.file_url.$error }"
            v-model="$v.payload.foto_padrao.file_url.$model"
            :state="$v.payload.foto_padrao.file_url.$dirty ?
            !$v.payload.foto_padrao.file_url.$error : null"
            ></b-form-file>
          </b-col>
        </b-row>
        <b-row class="mt-5 pb-3">
          <b-col lg="4">
            <h5 class="text-center">Foto Telhado</h5>
            <div class="w-100 py-3 container-img">
              <div class="h-100 position-relative">
                <img :src="foto_telhado" class="h-100" alt="">
                <div class="hover">
                  <div v-if="this.payload.foto_telhado.status === 'rejected'">
                    <i class="fas fa-ban"></i><br>
                    <strong>IMAGEM REJEITADA</strong>
                  </div>
                </div>
              </div>
              <div class="w-100 py-3">
                <b-button
                v-if="$acl.check('isAdmin')"
                variant="warning"
                size="sm"
                @click="confirmReject(payload.foto_telhado.id)"
                class="mr-2">
                  Reprovar
                </b-button>
                <b-button variant="danger" size="sm" @click="confirmRemove(payload.foto_telhado.id)">
                  Remover
                </b-button>
              </div>
            </div>
            <b-form-file
            v-if="!payload.foto_telhado.id"
            name="foto_telhado"
            @change="setImage"
            accept="image/*"
            placeholder="Selecione uma imagem"
            browse-text="Selecionar"
            :class="{ 'form-group--error': $v.payload.foto_telhado.file_url.$error }"
            v-model="$v.payload.foto_telhado.file_url.$model"
            :state="$v.payload.foto_telhado.file_url.$dirty ?
            !$v.payload.foto_telhado.file_url.$error : null"
            ></b-form-file>
          </b-col>
          <b-col lg="4">
            <h5 class="text-center">Foto Fio</h5>
            <div class="w-100 py-3 container-img">
              <div class="h-100 position-relative">
                <img :src="foto_fio" class="h-100" alt="">
                <div class="hover">
                  <div v-if="this.payload.foto_fio.status === 'rejected'">
                    <i class="fas fa-ban"></i><br>
                    <strong>IMAGEM REJEITADA</strong>
                  </div>
                </div>
              </div>
              <div class="w-100 py-3">
                <b-button
                v-if="$acl.check('isAdmin')"
                variant="warning"
                size="sm"
                @click="confirmReject(payload.foto_fio.id)"
                class="mr-2">
                  Reprovar
                </b-button>
                <b-button variant="danger" size="sm" @click="confirmRemove(payload.foto_fio.id)">
                  Remover
                </b-button>
              </div>
            </div>
            <b-form-file
            v-if="!payload.foto_fio.id"
            name="foto_fio"
            @change="setImage"
            accept="image/*"
            placeholder="Selecione uma imagem"
            browse-text="Selecionar"
            :class="{ 'form-group--error': $v.payload.foto_fio.file_url.$error }"
            v-model="$v.payload.foto_fio.file_url.$model"
            :state="$v.payload.foto_fio.file_url.$dirty ?
            !$v.payload.foto_fio.file_url.$error : null"
            ></b-form-file>
          </b-col>
          <b-col lg="4">
            <h5 class="text-center">Foto Croqui</h5>
            <div class="w-100 py-3 container-img">
              <div class="h-100 position-relative">
                <img :src="foto_croqui" class="h-100" alt="">
                <div class="hover">
                  <div v-if="this.payload.foto_croqui.status === 'rejected'">
                    <i class="fas fa-ban"></i><br>
                    <strong>IMAGEM REJEITADA</strong>
                  </div>
                </div>
              </div>
              <div class="w-100 py-3">
                <b-button
                v-if="$acl.check('isAdmin')"
                variant="warning"
                size="sm"
                @click="confirmReject(payload.foto_croqui.id)"
                class="mr-2">
                  Reprovar
                </b-button>
                <b-button variant="danger" size="sm" @click="confirmRemove(payload.foto_croqui.id)">
                  Remover
                </b-button>
              </div>
            </div>
            <b-form-file
            v-if="!payload.foto_croqui.id"
            name="foto_croqui"
            @change="setImage"
            accept="image/*"
            placeholder="Selecione uma imagem"
            browse-text="Selecionar"
            :class="{ 'form-group--error': $v.payload.foto_croqui.file_url.$error }"
            v-model="$v.payload.foto_croqui.file_url.$model"
            :state="$v.payload.foto_croqui.file_url.$dirty ?
            !$v.payload.foto_croqui.file_url.$error : null"
            ></b-form-file>
          </b-col>
        </b-row>
      </div>
      <hr>
      <div class="w-100 p-3">
        <b-form-group label="Observações">
          <b-form-textarea v-model="payload.observations" rows="5"></b-form-textarea>
        </b-form-group>
        <div class="w-100 d-flex justify-content-end" v-if="!isset_inspection">
          <b-button variant="primary" @click="save">Solicitar vistoria técnica</b-button>
        </div>

        <div class="w-100 d-flex justify-content-end" v-if="isset_inspection">
          <b-button variant="info" @click="save">Atualizar comentário</b-button>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ImageEmpty from '@/assets/images/tile-empty.png';
import UploadService from '@/Services/UploadService';
import PreInspectionService from '@/Services/PreInspectionService';
// import InspectionService from '@/Services/InspectionService';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';
import Loading from '@/components/global/loading.vue';

export default {
  props: ['id'],
  components: {
    Loading,
  },
  data() {
    return {
      foto_medidor: ImageEmpty,
      foto_disjuntor: ImageEmpty,
      foto_padrao: ImageEmpty,
      foto_telhado: ImageEmpty,
      foto_fio: ImageEmpty,
      foto_croqui: ImageEmpty,
      client: {},
      images: [],
      loading: false,
      id_remove: '',
      id_reject: '',
      isset_inspection: false,
      payload: {
        observations: '',
        foto_medidor: {
          id: '',
          file_url: null,
          status: '',
        },
        foto_disjuntor: {
          id: '',
          file_url: null,
          status: '',
        },
        foto_padrao: {
          id: '',
          file_url: null,
          status: '',
        },
        foto_telhado: {
          id: '',
          file_url: null,
          status: '',
        },
        foto_fio: {
          id: '',
          file_url: null,
          status: '',
        },
        foto_croqui: {
          id: '',
          file_url: null,
          status: '',
        },
      },
    };
  },
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  validations: {
    payload: {
      foto_medidor: {
        file_url: { required },
      },
      foto_disjuntor: {
        file_url: { required },
      },
      foto_padrao: {
        file_url: { required },
      },
      foto_telhado: {
        file_url: { required },
      },
      foto_fio: {
        file_url: { required },
      },
      foto_croqui: {
        file_url: { required },
      },
    },
  },
  methods: {
    async getAll() {
      this.loading = true;
      const params = {
        project_id: this.id,
      };

      const { data } = await PreInspectionService.getList(params);

      if (data.inspection !== null) this.isset_inspection = true;

      if (data.pre_inspection !== null) {
        this.payload.observations = data.pre_inspection.observations;
      }

      this.client = {
        ...data.client,
        city: {
          ...data.city,
        },
      };

      data.pre_inspection_attachments.forEach((item) => {
        this[item.type] = `${process.env.VUE_APP_API}files/${item.file.path}/${item.file.filename}`;
        this.payload[item.type] = {
          id: item.id,
          file_url: `${process.env.VUE_APP_API}files/${item.file.path}/${item.file.filename}`,
          status: item.status,
        };
      });
      this.loading = false;
    },

    async removeImage() {
      try {
        const { data } = await PreInspectionService.delete(this.id_remove);
        this[data.type] = ImageEmpty;
        this.payload[data.type].id = '';
      } catch (error) {
        console.log(error);
      }
    },

    async setImage(event) {
      const file = event.target.files[0];
      const { name } = event.target;

      const payload = {
        project_id: this.id,
        origin: 'pre-inspection',
        type: name,
      };

      const formData = new FormData();
      formData.append('file', file);
      formData.append('payload', JSON.stringify(payload));

      await UploadService.send(formData);
      this.getAll();
    },

    checkImages() {
      console.log(this.images.length);
    },

    confirmRemove(id) {
      this.id_remove = id;
      this.$toast.question(
        'Deseja realmente remover essa imagem ?',
        'Atenção',
        this.getQuestionObj(this.removeImage, '', 'Imagem removida'),
      );
    },

    confirmReject(id) {
      this.id_reject = id;
      this.$toast.question(
        'Deseja realmente rejeitar essa imagem ?',
        'Atenção',
        this.getQuestionObj(this.rejectAttachment, '', 'Imagem rejeitada'),
      );
    },

    async rejectAttachment() {
      try {
        await PreInspectionService.rejectAttachment(this.id_reject);
        this.getAll();
      } catch (error) {
        console.log(error);
      }
    },

    async save() {
      this.$v.payload.$touch();
      const user = JSON.parse(localStorage.getItem('user'));
      let payload = {};

      if (this.isset_inspection) {
        payload = {
          project_id: this.id,
          observations: this.payload.observations,
        };
      } else {
        payload = {
          pre_inspection: {
            project_id: this.id,
            observations: this.payload.observations,
          },
          inspection: {
            project_id: this.id,
            user_id: user.id,
            status_id: 1,
          },
        };
      }

      if (!this.$v.payload.$invalid) {
        if (this.isset_inspection) {
          await PreInspectionService.edit(payload);
          this.messageSuccess('Comentário atualizado com sucesso');
        } else {
          await PreInspectionService.save(payload);
          this.messageSuccess('Vistoria solicitada com sucesso');
          this.$router.push({ name: 'projects' });
        }
      } else {
        this.messageError('Faça o upload das images solicitadas antes de solicitar a vistoria. :(');
      }
      this.checkImages();
    },
  },

  created() {
    this.getAll();
  },
};
</script>

<style lang="css">
  .container-img{
    height: 210px;
    text-align: center;
  }
  .hover{
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f12300;
  }

  .hover i{
    font-size: 60px;
  }
</style>
