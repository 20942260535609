/* eslint-disable class-methods-use-this */
import http from '@/http';
import Service from './Service';

class UploadService extends Service {
  send(file) {
    return http.post('upload', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

const uploadService = new UploadService();
export default uploadService;
